<template>
  <ClientOnly>
    <img
      class="hot-label"
      :src="imgUrl"
    />
  </ClientOnly>
</template>

<script setup>
import { computed } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
const imgUrl = computed(() => {
  const { GB_cssRight } = gbCommonInfo || {}
  if (GB_cssRight) {
    return '//img.ltwebstatic.com/images3_ccc/2024/10/11/e2/172863351076c1f35077b0f7a34220ee1572de0f95.png'
  }
  return '//img.ltwebstatic.com/images3_ccc/2024/10/11/e2/17286335102d816c381b69a07d03dab7b0da595bbc.png'
})
</script>

<style lang="less" scoped>
.hot-label {
  position: absolute;
  width: 34px;
  height: 14px;
  top: -4px;
  right: -2px;
}
</style>
